 body {margin: 0; height: 100%; 
}
.owl-carousel{
  z-index: 0!important;
}
.tb_hc_image_wrap_{
  height: 500px;
  width: 100%;
}
.comingsoon{
margin: 90px 0px;
font-family: 'poppins';
}
.jobdesc{
  height: 430px;
  overflow-y: scroll;
}
.comingsoon h4{
  text-align: center;
  font-size: 45px;
font-weight: 500;
}
.blink-soft {
  animation: blinker 1.5s linear infinite;
  font-family: 'poppins';
  color: '#ca2529'!important;
  font-weight: 500;
}
@keyframes blinker {
  50% {
    color: #fff;
  }
}
.aboutleftimg video{
width: 80%;
}
.red-div-head h1{
  text-align: center;
}
.red-div-head p{
  font-family: 'poppins'!important;
  color: #383636!important;
  font-weight: 400!important;
  font-size: 15px!important;
  line-height: 30px!important;
}
.taggbox li{
  margin: 10px!important;
}
.tb_hc_image{
  height: 500px!important;
}
.tb_hc_fixed_height .tb_hc_post_wrapper{
  height: 500px!important;
}
#taggbox_main .tb_hc_author_wrapper{
  display: none!important;
}
.tb_hc_content_wrapper__{
  display: none!important;
}
.tb_hc_image_wrap{
  height: 500px;
}
.tb_hc_contant_wrapper{
  display: none!important;
}
.extraclass{
  margin-left: 10px;
}
.custom-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Black overlay */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}
.custom-modal {
  background-color: #000;
  border-radius: 8px;
  padding: 20px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
  max-width: 25%;
  max-height: auto;
  overflow: auto;
  position: relative;
  border-radius: 0px 30px 0px 0px;
}
.custom-modal .modal-body {
  padding: 0;
}
.custom-modal button.close {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  cursor: pointer;
  font-size: 25px;
  color: #333;
  padding: 0;
}

.owl-carousel .owl-item img {
  display: block;
  width: 100%;
  height: auto;
}
.fw-bold{
  font-weight: 600!important;
}
.navbar {
  transition: top 0.3s ease, z-index 0.3s ease;
  position: fixed;
  top: 0;
  z-index: 0;
}
.navbar.hidden {
  top: -100px; /* Set to whatever your height of navbar is */
  z-index: 0; /* Keep z-index consistent */
}
.navbar.visible {
  z-index: 999999; /* Ensure navbar appears on top */
}
.dropdown-toggle{
  border: 0px;
  background-color: #fff;
  font-family: 'Poppins' !important;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}
.bgmate .headtitle{
  color: #fff!important;
}
.bgmate .marquee span
{
  color :rgb(241 235 235 / 35%)
}
.imgbg{
  background-image: url('q.png') ;
  background-size: contain;
  background-repeat: no-repeat;
  padding: 40px 20px;
}
.instagramstrip{
  margin-bottom: 0px!important;
}
.video-bg{
  padding: 40px 20px;
}
  #video {
    display: inline-block;
    border-radius: 15px;
    background: #000;
    height: 100%;
    padding: 1%;
    margin: auto;
    display: block;
}
.bannerimgleft{
  float: right
  ;
}
.bgmate{
  background-color: #151515 ;
  padding: 40px 50px 40px 30px;

  border-radius: 0px 50px 0px 0px;
}
.fa-inta{
  color: rgba(252, 204, 99);
}
.instabtn{
  padding: 10px 35px;
  background-color: #fff;
  border-radius: 0px 30px 0px 0px;
  color: #8c0307;
  font-size: 18px;
  font-weight: 500;
  float: right;
  transition: background-color 0.5s;
}
.instabtn:hover{
  background-color: #8c0307;
  border-radius: 0px 30px 0px 0px;
  color: #fff;
}
.bgmate h6{
  color: #fff;
  font-size: 32px;
  font-family: 'poppins';
  font-weight: 400;
  margin-top: 40px!important;
  margin-bottom: 30px;
}
.bgmate b{
  font-weight: 500;

}
.Founder p{
  color: #000;
  font-family: 'Poppins';
  font-size: 20px;
  font-style: normal;
  font-weight: 300;
  line-height: 30px;
  margin-top: 30px;
}
.highlight{
background-color: #000;
color: #fff!important;
font-weight: 500!important;
width: fit-content;
padding: 0px 20px;
}
.ti-cursor{
  display: none;
}
.Founder h6{
  color: #000!important;
  font-weight: 400!important;
  font-size: 18px;
  font-family: 'poppins';
  margin-top: -10px;
}
.Founder h5{
  color: #000!important;
  font-weight: 600!important;
  font-size: 24px;
  font-family: 'poppins';
}
.founderimg{
  border-radius: 0px 30px 0px 0px;
  width: 95%;
  margin: auto;
  display: block;
}
.red{
  color: #c92429!important;
}
.fa-quote-left{
  color: #c92429;
  font-size: 34px;
  position: relative;
  right: 21px;
  top: 28px;
}
.fa-quote-right{
  color: #c92429;
  font-size: 34px;
  position: relative;
  left: 0px;
  float: right;
  top: -30px;
}
.upload-Resume{
  border: 1px dotted #585858;
  height: 100px;
  color: #fff;
}

.red-circle-ag{
  width: 24px;
  position: relative;
  top: 20px;
  right: 20px;
  height: 24px;
  background-color: red;
  border-radius: 50%;
  animation: zoomInOut 4s infinite; /* Adjust the animation duration as needed */
  color: white;
  transform-origin:top left;
}

.resumeleftcont{
  padding: 20px;
}
.jobdetail ul li{
  list-style-type: none;
}
.jobdetail{
  font-family: 'poppins';
  color: #000!important;
  font-size: 15px!important;
  font-weight: 300;
  padding: 0;
  margin-bottom: 10px;
}
.jobdetail p{
  margin: 5px;
}
.resumeleftcont h4{
  font-size: 24px;
  font-family: 'poppins';
  color: #ca2529;
  font-weight: 600;
}

.jobdesc {
  font-size: 14px!important;
  font-family: 'poppins';
  color: #1488af;
  font-weight: 600;
  margin-top: 20px;
}
.resumeleftcont .exp{
  font-size: 16px;
  font-family: 'poppins';
}
/* career */
.jobbox{
  background-color: #ca2529;
  border-radius: 0px 30px 0px 0px;
  margin-bottom: 20px;
}
.aplynow button{
  margin-top: 10px;
  background-color: #fff;
  color: #860003;
  border:0px;
  font-family: 'poppins';
  font-size: 14px;
  text-align: center;
  padding: 10px 25px;
  font-weight: 500;
  border-radius: 0px 15px 0px 0px;
  transition: border-radius 1s ease;

}
.careercard{
}

.aplynow1 button{
  margin-top: 10px;
  background-color: transparent;
  color: #fff;
  border:0px;
  font-family: 'poppins';
  font-size: 14px;
  text-align: left;
  padding: 4px 0px;
  font-weight: 400;
  border-radius: 0px 30px 0px 0px;
  transition: border-radius 1s ease;

}
.aplynow1 .fa{
  color: #fff;
  margin-top: 2px;
  margin-left: 10px;
}
.jobtype span{
  padding: 4px 20px;

  font-size: 12px;
  color: #fff;
  font-family: 'poppins';
  font-size: 14px;
  text-align: center;
}
.aplynow button:hover
{
border-radius: 30px;
}
.jobboxexp
{
  margin-top: -26px;
  font-size: 14px!important;
  padding: 0px;
}
.jobbox{
  padding:20px  30px;
}
.jobbox h3{

  font-size: 20px;
  color: #fff;
  font-weight: 500;
  font-family: 'poppins';
}
.jobdivider{
  margin: 10px auto;
  height: 0.3px;
  background-color: #fff;
}
.jobbox p{
  font-size: 15px;
  color: #fff;
  font-weight: 400;
  font-family: 'poppins';
}
input#file-upload-button{
  font-size: 12px;
  background-color: black;
  color: white;
}




.ctabtnpopup button{
  margin-top: 10px!important;
}
.ctabtnpopup .close{
  position: absolute!important;
  float: right!important;
  top: 13px!important;
  right: 18px!important;
  width: 30px!important;
  height: 30px!important;
  background-color: #fff!important;
  color: #000!important;
  border-radius: 30px!important;
}
.ctabtnpopup .close span{
  left: 8px;
  top: -3px;
  font-size: 22px;
  font-weight: 600;
  position: absolute;
}


.ctabtnpopupreel button{
  margin-top: 5px!important;
}
.ctabtnpopupreel .close{
  position: absolute!important;
  float: right!important;
  top: 10px!important;
  right: 18px!important;
  width: 30px!important;
  height: 30px!important;
  background-color: #fff!important;
  color: #000!important;
  border-radius: 30px!important;
}
.ctabtnpopupreel .close span{
  left: 8px;
  top: -3px;
  font-size: 22px;
  font-weight: 600;
  position: absolute;
}
.modal-content{
  border-radius: 0px 60px 0px 0px!important;
}

.modal button{
  margin: auto;
  float: none;
}
.modal-body{
  border-radius: 0px 40px 0px 0px!important;
  background-color: #141414;

}

.modal-body input, textarea {
  border-bottom: 1px solid #585858!important;
  width: 100%;
  font-family: 'poppins';
  font-size: 16px;
  text-align: left;
  color: #fff!important;
  border-top: 0px!important;
  background-color: transparent!important;
  border-left: 0px!important;
  border-right: 0px!important;
  padding: 20px 0px 10px 0px!important;
  margin-bottom: 20px;

}
input:focus{
  border: none;
  outline: none;
}

.red-div{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  background-color: #fbfbfb;
}
.red-div img{
  margin: 30px;
  height: 25vh;
}
.red-div-head{
  padding-left: 20px;
  margin-top: 30px;
  text-align: start;
  align-items: center;
  justify-content: center;
}
.red-div-head h1{
  font-size: 28px;
  font-family: "poppins";
  font-weight: 600;
}
.black-div{
  padding: 0px 30px 40px;
}
.black-div h1{
  font-size: 24px;
  color: #fff;
  margin: 15px auto 25px 0px;
  font-family: 'poppins';
}
.black-div textarea{
  border-left: 0px!important;
  border-right: 0px!important;  
  border-top: 0px!important;
  border-bottom: 1px solid #585858!important;
  color: white;
  padding: 0px;
}
textarea:focus{
  border: none;
  outline: none;
}
.btnform{
  color: #fff;
border-radius: 0px 30px 0px 0px ;
padding: 7px 30px;
text-align: center;
background-color: #860003;
border: 0px;
margin: 25px auto;
display: block;
font-size: 15px;
font-family: 'poppins';
}

.cta-capt .ctabtn{
  float: left!important;
}
.sec-menu{
  margin-bottom: 30px;
}

.owl-dots{
  display: none;
}
@keyframes blink {
  0% {
    color: #ffffff;
}
10% {
    color: #fbee2a;
}
20% {
    color: #e60c8a;
}
30% {
    color: #43ace2;
}
40% {
    color: #fbee2a;
}
50% {
    color: #e60c8a;
}
60% {
    color: #43ace2;
}
70% {
    color: #fbee2a;
}
80% {
    color: #e60c8a;
}
90% {
    color: #43ace2;
}
100% {
    color: #ffffff;
}
}

.modal-dialog{
  margin-top: 30px;
}

video{
  width:100%;

    margin: auto;
    display: block;
}
.close{
  width: 30px;
  position: absolute;
  right: 0px;
  z-index: 999;
  border: 0px;
}
.modal-body{
  padding: 0px;
}
a{
  text-decoration: none;
  color: #000;
}
.social-logo .fa{
  color: #fff;
}
.navbar-hide{
  animation: 22s ease-in!important;
}
.navbar-hide {
  opacity: 1;
  transition: opacity 0.3s ease; /* Adjust the timing as needed */
}
.form-control{
  color: #fff;
}
.navbar-hide.hidden {
  opacity:0;
}


.ctabtn {
  position: relative; /* Add position relative to the button */
  padding: 10px 20px;
  font-weight: 500;
  font-size: 15px!important;
  color: #fff;
  text-align: center;
  border-radius: 0px 20px 0px 0px;
  width: fit-content;
  background-color: #CA2529;
  border: 0px;
  font-family: 'poppins';
  font-size: 14px;
  transition: border-radius 1s ease;
  float: right;
}
.ctabtn-contact {
  position: relative; /* Add position relative to the button */
  padding: 10px 14px;
  font-size: 13px!important;
  color: #fff;
  text-align: center;
  border-radius: 0px 30px 0px 0px;
  width: fit-content;
  background-color: #CA2529;
  border: 0px;
  font-family: 'poppins';
  font-size: 20px;
  transition: border-radius 1s ease;
  float: center;
}
.ctabtn-contact:hover svg {
  transform: translate(5px, -50%); /* Move the SVG slightly to the left on hover */
}

.ctabtn-contact:hover {
  border-radius: 30px 30px 30px 30px;
}

.ctabtn svg {
  top: 50%; /* Align the SVG vertically */
  right: 10px; /* Adjust the right position as needed */
  transform: translateY(-50%); /* Center the SVG vertically */
  transition: transform 0.5s ease; /* Add transition for smooth animation */
  margin-top: 8px;
  margin-left: 4px;
}

.ctabtn:hover svg {
  transform: translate(5px, -50%); /* Move the SVG slightly to the left on hover */
}

.ctabtn:hover {
  border-radius: 30px 30px 30px 30px;
}



.navbar-show{
  display: block;
  position: fixed!important;
  box-shadow: rgba(0, 0, 0, 0.15) 1cap 5px 15px 0px;
}
.navbar {
  /* Your navbar styles */
  transition: top 0.3s;
}

.navbar-hidden {
  top: -100px; /* Adjust as needed */
}

.navbar-visible {
  top: 0;
}
#navbar{
  /* position: fixed!important; */
}
.navbar {
  /* Your navbar styles */
}

.navbar-hidden {
  top: -100px; /* Adjust as needed */
}

.navbar-visible {
  top: 0;
}


.navbar-collapse{
  flex-grow: inherit;
}
.image-container {
  position: relative;
  overflow: hidden;
}
.absnewtxt{
  font-family: 'poppins'!important;
  font-size: 14px!important;
  padding-left: 6px!important;
  color: #000!important;
  font-weight: 400!important;
  line-height: 20px!important;
  ;
}
.zoom-img {
  width: 100%;
  height: auto;
  display: block; /* Ensures the image fills its container */
}

.mega-link1 {
  /* Your styles for the mega link */
  position: relative;
  width: fit-content!important;
}

.mega-link1::before {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  width: 0;
  height: 3px;
  background-color: red;
  margin-bottom: -4px;
  transition: width 0.3s ease; /* Smooth transition for width change */
}

.image-container:hover .mega-link1::before {
  width: 100%; /* Expand the width to 100% on hover */
}

#footer1{
  z-index: 0;
  padding: 60px 50px 0px 60px;
}

.zoom-img:hover + .mega-link {
  border-bottom: 2px solid red;
  transition:  0.3s ease;
}
.newtxt{
  font-family: 'poppins';
  font-size: 15px!important;
  color: #000!important;
  font-weight: 300!important;
}
.meals-showcase {
  list-style: none;
  width: 100%;
}

.meals-showcase li {
  display: block;
  float: left;
  width: 25%;
}

/* to make img as wide as its container */
.meal-photo img {
  opacity: 0.9;
  width: 100%;
  height: auto;
  transition: transform 0.5s, opacity 0.5s;
}

/* Apply styles to the adjacent .col-md-5 when hovering over .col-md-7 */
.km-spacing-company :hover .col-md-5 .meal-photo img {
  opacity: 1;
  border-radius: 0px 30px 0px 0px;
  transform: scale(1.03);
}

/* Add transition effect for smoother animation */
.km-spacing-company:hover .col-md-5 .meal-photo img {
  transition: transform 0.5s, opacity 0.5s, border-radius 0.5s;
}
.company-km-img:hover {
  transition: transform 0.5s, opacity 0.5s, border-radius 0.5s;

}


.zoom-img {
  width: 100%; /* Set image width to 100% */
  transition: transform 0.3s ease; /* Smooth transition for zoom effect */
}

/* Hover effect for image zoom */
.zoom-img:hover {
  transform: scale(1.02);
}


.link {
  position: relative;
  color: white;
  text-decoration: none;
}
.link::before {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  width: 0;
  height: 2px;
  background-color: #ba1c20;
  transition: width 0.3s ease;
}
.link:hover::before {
  width: 100%;
}
/*Megamune link*/
.mega-link{
  position: relative;
  color: white;
  text-decoration: none;
}
.mega-link::before{
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  width: 0;
  height: 2px;
  background-color: #ba1c20;
  transition: width 0.3s ease;
}

.mega-link:hover::before {
  width: 100%;
}

/*Footer link*/
.footer-link{
  position: relative;
  color: white;
  text-decoration: none;
}
.footer-link::before{
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  width: 0;
  height: 2px;
  background-color: #ba1c20;
  transition: width 0.3s ease;
}

.footer-link:hover::before {
  width: 60%;
}



.social-logo a{
  color: #fff;
}
.card-details .para{
  pointer-events: none;
}
.dropdown-menu{
  border: 0px!important;
}

.dropdown-toggle::after{
  border: 0!important;
}
.exploremore .arrow__animate svg {
  width: 170px;
  transform: translateX(0);
  transition: 0.5s;
  float: left;
  left: -45px;
  position: relative;
  top: -16px;
}

.exploremore .arrow__animate svg.variant1 {
  transform: translateX(-50%);
}

.exploremore .arrow__animate svg rect {
  transform: scale(0.75, 1);
  transition: 0.5s;
  transform-origin: right;
}

.exploremore:hover .arrow__animate svg {
  width: 245px;
  left: -60px;
  transform: translateX(25%);
}

.exploremore:hover .arrow__animate svg rect {
  transform: scale(1, 1);
}

.exploremore .arrow__animate path,
.exploremore .arrow__animate rect {
  transition: 0.5s;
}

.contact-top{
  padding: 0px 30px;
}
.abc{
position: absolute;
right: 0px;
width: 248px;
height: 48px;
line-height:24px;
text-align: center;
transform-style: preserve-3d;
transform: translate(-17%, -0%);
transform-origin: center center;
}
.spanbtn
{
position: absolute;
padding:11px;
top: -24px;
left: 0;
width: 100%;
height: 100%;
display: block;
justify-content: center;
font-size: 13.5px;
font-family: "Poppins";
background: rgba(255,255,255,.05);
transform-style: preserve-3d;
backface-visibility: hidden;
border-radius: 0px 30px 0px 0px;
text-transform: capitalize;
color: #fff;
transition: 1s;
text-align: center;
}
.spanbtn.front
{
transform: rotateX(0deg) translateZ(20px);
}
.abc:hover .spanbtn.front
{
transform: rotateX(180deg) translateZ(20px);
}
.spanbtn.back
{
transform: rotateX(180deg) translateZ(20px);
}
.abc:hover .spanbtn.back
{
transform: rotateX(0deg) translateZ(20px);
}
.spanbtn.center
{
background:  #ba1c20;
}
.spanbtn.center:before
{ content: '';
position: absolute;
top: 0;
left: 0;
width: 100%;
height: 100%;
background: #8d0407;
border-radius:0px 30px 0px 0px;
transform: translateZ(-1px);
}
.abc:hover .spanbtn.center
{
transform: rotateX(360deg);
}
.meals-showcase {
  list-style: none;
  width: 100%;
}

.meals-showcase li {
  display: block;
  float: left;
  width: 25%;
}

/* to make img as wide as its container */
.meal-photo img {
  opacity: 0.9;
  width: 100%;
  height: auto;
  transition: transform 0.5s, opacity 0.5s;
}

/* Apply styles to the adjacent .col-md-5 when hovering over .col-md-7 */
.col-md-7:hover + .col-md-5 .meal-photo img {
  opacity: 1;
  border-radius: 0px 30px 0px 0px;
  transform: scale(1.03);
}

/* Add transition effect for smoother animation */
.col-md-7:hover + .col-md-5 .meal-photo img {
  transition: transform 0.5s, opacity 0.5s, border-radius 0.5s;
}
/* Add this CSS to your existing styles */

/* Team.css */



#preloader-container {
  text-align: center;
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #fbfbfb;
  
}

#preloader {
  width: 250px; /* Set the width of your logo */
  height: 250px; /* Set the height of your logo */
  background-size: cover;
  display: inline-block;
}
/* Styles for desktop view (card grid) */
.preloader-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh; /* Adjust as needed */
}

.preloader-img {
  max-width: 100%;
  max-height: 100%;
}


.red-circle-apply{
  width: 22px;
  position: relative;
  top: 35px;
  /* z-index: -1; */
  right: 10px;
  height: 22px;
  background-color: red;
  border-radius: 50%;
  animation: zoomInOut 2s infinite; /* Adjust the animation duration as needed */
  color: white;
}


.navbar-nav li {
  position: relative;
}

.navbar-nav .new:after {
  content: '';
  position: absolute;
  top: 0;
  margin-top: -8PX;
  left: 42%; /* Adjust the left position to center the line */
  transform: translateX(-50%); /* Center the line horizontally */
  width: 50%; /* Set the width to 50% or adjust as needed */
  height: 4px;
  background-color: transparent; /* Set your initial color for the upper line */
  transition: width 0.3s ease, background-color 0.3s ease; 
}

.navbar-nav a:hover:after {
  width: 80%;
  background-color: red; /* Set your desired hover color */
}

.navbar-nav a.active:after {
  width: 75%;
  background-color: red; /* Set your desired active color */
}

.upper-line {
  display: none; /* Hide the upper-line div since we're using :after for the border */
}

.navbar-nav a:hover .upper-line,
.navbar-nav a.active .upper-line {
  display: block; /* Make sure it's displayed when needed */
}



::-webkit-scrollbar {
  display: none;
}
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100..900&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
.banner-hero {
 background-size: cover;
 background: linear-gradient(100deg, #CA2529 -18.22%, #860003 86.5%);
} 
.navbar-expand-lg {
  z-index: 9;
}
#svgContainer {
  width: 199px;
  height: 16px;
  transition: width 0.3s ease, height 0.3s ease; /* Add a smooth transition effect for both width and height */
}

/* Change width and height on hover */
#svgContainer:hover {
  width: 300px; /* Adjust the width as needed */
  height: 24px; /* Adjust the height as needed */
}

.nasmate{
  margin: 0!important;
    font-family: 'poppins'!important;
    font-size: 15px!important;
    font-weight: 300!important;
    text-align: center!important;
    color: #000!important;
}


.btnnew button{
  border-radius: 0px 15px 0px 0px;
  margin-right: 15px; 
background: #CA2529;
display: inline-flex;
font-size: 14px;
padding: 8px 19.381px 8px 13px;
align-items: center;
gap: 10px;
border: 0px;
color: #fff;
font-family: 'Poppins';
margin: 0px 5px ;

}
.navbar-nav {
  align-self: center;
}

.bannerul span{
  font-size: 15px!important;
}
.any-request ul li a{
  padding:4px 10px 4px 0px!important;
}
ul li a,.navbar-nav .nav-link{
  padding:4px 20px 4px 6px!important;
  color: #000;
font-family: 'Poppins'!important;
font-size: 15px;
font-style: normal;
font-weight: 400;
line-height: normal;
}
nav {
  position: absolute !important;
  margin: 20px 40px;
  border-radius: 0px 35px 0px 0px;
  background: #fff;
  width: 94%;
  padding: auto 30px !important;
  backdrop-filter: blur(59.5px);
}
.nav .container-fluid{
  padding: auto 30px !important;
}
.logotop {
  width: 100%;
}

h2{
  color: #FFF;
padding-top: 160px;
font-family: "Poppins";
font-size: 65px;
font-style: normal;
font-weight: 700;
line-height: 60px; /* 86.957% */
}

h3{
  color: #F5F5F5;
  font-family: "Poppins";
  font-size: 35px;
  font-style: normal;
    font-weight: 700;
  line-height: 60px;
  text-transform: uppercase;
}

h6
{
  color: #F5F5F5;

  font-family: 'Inter';
  font-size: 22px;
  margin-top: 18px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
}


.connectusbtn{
  color: #000;

  font-family: 'Inter';
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  display: flex;
  padding: 14px 30px;
  border: 0px;
  text-align: center;
  border-radius: 0px 30px 0px 0px;
background: #EEDE4F;
}
.btnarea{
  display: flex;
justify-content: center;
padding-top:70px;

}
.btnarea a{
  color: #fff;
  text-decoration: none;
  margin-left: 30px;
  margin-top: 15px;
}
a svg{
  margin-top: 6px;
}
.navbar-expand-lg{
  padding: 10px 30px 5px;
  background-color: white!important;
}
#ptb-30{
  padding: 20px 0px 40px 0px;
}
.any-request{
  padding:15px;
}

.any-request ul{
  margin: 0px!important;
}
.card{
  border: 0px;
  
}
.button-cta svg{
  margin: auto;
}
.caption{
  position: absolute;
  bottom: 0px;
  background-color: #D9D9D9;
  width: 100%;
  padding: 18px 20px;
  border-radius: 0px 40px 0px 0px;
background: rgba(217, 217, 217, 0.10);
backdrop-filter: blur(6px);
}
.caption p{
  color: #FFF;
font-family: 'Inter';
font-size: 18px;
font-style: Medium;
font-weight: 500;
line-height: 22px;
margin-bottom: 0;
}
.casestudy-btn{
  border-radius: 60px;
background: #EEDE4F;
}
.badge{
  color: #000!important;

font-family: 'Inter';
border-radius: 0px 15px 0px 0px;

font-size: 14px!important;
font-style: normal;
font-weight: 600;
line-height: normal;
background: #EEDE4F;
padding: 5px 20px;

}
.caption svg{
  float: right;
}

.privacy1 {
  padding: 20px 130px!important;
}

.privacy1 h4{
  font-family: 'poppins';
  font-size: 32px;
  font-weight: 500;
  margin: 30px auto;

}

.privacy1 p{
  font-family: 'poppins';
  font-size: 18px;
  font-weight: 300;
}

.tb_hc_arrow{
  height: 40px!important;
  width: 40px!important;
  border-radius: 50px!important;
  font-size: 15px!important;

}
/* section */

section .headtitle{
  text-align: center;
  color: #000;
  position: relative;
width: fit-content!important;
font-family: 'Poppins';
font-size: 36px;
font-style: normal;
font-weight: 700;
line-height: normal;
margin: 10px auto;
width: 251px;
}
@keyframes zoomInOut {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(2);
  }
  100% {
    transform: scale(1);
  }
}

.exploremore {
  display: flex;
  padding-left: 56px;

  
}

.exploremore h5{
  font-size: 30px;
  font-family: 'Inter';
  font-weight: 600;
  margin: 0;
  margin-top: 12px;
}





/* Adjusting the position of the "Read More" text when hovering over the SVG */
.readmorearea:hover .readmore {
  left: 35px; /* Adjust this value according to your layout */
}
.headcontainer{
  width: fit-content;
  margin:0px auto 30px auto;
}
.cardwiththree img{
  max-height: 280px;
  /* object-fit: contain; */
}
.cardwithimage img{
  max-height: 190px;
}
.cardwithimage{
/* height: 208px; */
flex-shrink: 0;
/* background: linear-gradient(268deg, #CA2529 29.65%, #000 148.63%); */
}
.divider-20{
  padding: 10px;
}
.red-circle {
  width: 26px;
  position: relative;
  top: 25px;
  /* z-index: -1; */
  right: 24px;
  height: 26px;
  background-color: red;
  border-radius: 50%;
  animation: zoomInOut 4s infinite; /* Adjust the animation duration as needed */
  color: white;
  transform-origin: top left;
}

.media-play-button{
  position: absolute;
  top: 40%;
  left: 50%;
  color: white;
  width: 40px;
  height: 10vh;
}
.about-play-button{
  position: absolute;
  top: 112%;
  right: 21%;
  color: white;
  width: 40px;
  height: 10vh;
}


/* agency */

#agencies{
  border-radius: 0px 70px 0px 0px;
background: linear-gradient(180deg, #151515 0%, #202020 100%);

}

.headcontainer2{
  width: fit-content;
  margin:0px auto 30px auto;
  color: #fff;
}

.red-circle2 {
  width: 16px;
  position: relative;
  top: 57px;
  /* z-index: -1; */
  left: -13px;
  height: 16px;
 
  background-color: red;
  border-radius: 50%;
  animation: zoomInOut 2s infinite;
  color: white;
}


section .headtitle2 {
  color: #fff;

font-family: 'Poppins';
font-size: 35px;
font-style: normal;
font-weight: 700;
line-height: normal;
margin: 40px auto!important;
}


.agencyright p{
  margin-top: 140px;
  color: #FFF;
  font-family: 'Poppins';
  font-size: 23px;
  font-style: normal;
  font-weight: 300;
  line-height: 34px;
  width: 500px;
}
.aniamtionservic .title
{
  color: #FFF;
font-family: 'Poppins';
font-size: 28px;
font-style: normal;
font-weight: 500;
line-height: normal;
}
.line{
  height: 2px;
  width: 2%;
  margin-top: 2px;
  background: #EEDE4F;
  position: absolute;
  margin-top: 40px;
  transition: width 0.8s linear; /* Adjust the duration as needed */

}
.card-img-top{
  display: block;
  width: 100%;
  height: auto;
  border-radius: 0px 30px 0px 0px;
}

.title:hover .line {
  width: 98%!important;
}

.btn-service .capt p{
  color: #fff;
width: 474px;
color: #FFF;
font-family: 'Poppins';
font-size: 16px;
font-style: normal;
font-weight: 400;
margin-top: 10px;
line-height: normal;
}
.imgblog img{
  border-radius: 0px 60px 0px 0px;
}
.blogtitle .date{
  font-size: 18px;
  font-family: 'poppins';
  font-weight: 400;
}
.blogtitle .category{
  font-size: 18px;
  font-weight: 400;
  font-family: 'poppins';

}

.servicebtn{
  color: #000;
  padding:10px;
  font-family: 'Poppins';
font-size: 16px;
width: 100%;
margin: 10px;
font-style: normal;
  background: #EEDE4F;
  text-decoration: none;
}
.serviceimg
{
  width: 57%;
  height: auto;
  border-radius: 5px;
  margin: -61px auto;
  display: block;

}
.block-service {
  position: relative;
}

.aniamtionservic {
  position: relative;
}

.title {
  font-size: 24px;
  font-weight: bold;
  cursor: pointer;
  transition: color 0.3s;
}
.btn-company a{
  font-size: 18px;
  font-family: "Poppins";
  font-weight: 400;
  
}
.title:hover + svg + .line1 + .btn-service {
  opacity: 1;
  pointer-events: auto;
}
/******************Blog Page******************************/
.four-img{
  display: flex;
  /* padding-left: 80px; */
  padding-bottom: 20px;
}


.blog-img{
  width: 754px;
  /* height: 399px; */
  padding-top: 15px;
}
.img-blog img{
  width: 90%;
  /* width: 350px; */
  /* height: 329px; */
  /* padding: 0px 10px 0px 10px; */
  border-radius: 0px 60px 0px 0px;
}
.blog-head{
  padding: 90
  px 0px 0px 60px;
}
.blog-head h4{
  padding-top: 20px;
  font-size: 40px;
  font-family: 'Poppins';
  font-weight: 600;
}
.privacy{
  padding: 80px 0px 80px 60px;
}
.privacy h4{
  padding-top: 20px;
  font-size: 32px;
  font-family: 'Poppins';
  font-weight: 500;
  margin-bottom: 30px;
}
.privacy p{
  font-size: 18px;
  font-family: "Poppins";
  font-weight: 400;
}
.blog-title{
  font-size: 28px;
  font-family: "Poppins";
  font-weight: 400;
}
.blog-title p{
  padding: 15px 0px;
  color: #000;
font-family: 'Poppins';
font-size: 23px;
font-style: normal;
margin-bottom: 0px;
font-weight: 400;
line-height: 35px; /* 113.333% */
stroke-width: 1px;
}
.blog-title span{
  font-size: 18px;
  font-family: "poppins";
  font-weight: 400;
}
.blog-red-circle {
  width: 22px;
  position: relative;
  top: 12px;
  /* z-index: -1; */
  right: 20px;
  height: 22px;
  background-color: red;
  border-radius: 50%;
  animation: zoomInOut 2s infinite; /* Adjust the animation duration as needed */
  color: white;
}
.blog-strip{
  height: 4px;
  background: #CA2529;
  width: 60px;
  float: right;
  position: absolute;
  margin-top: -2px;
  right: 44.2%;
}
.bloggrid1{
  padding: 5px 75px 50px 75px;
}
.bord-left{
  border-right: 1px solid #202020;
}
.blogdivider1{
  margin: 15px auto;
}
/**********************************************************/
.btn-service {
  position: absolute;
  top: 100%;
  left: 0;
  opacity: 0;
  transition: opacity 0.5s;
  pointer-events: none;
  display: flex;
  flex-wrap: wrap;
  margin-top: 10px;
}

.servicebtn {
  display: block;
  padding: 10px;
  margin-bottom: 10px;
  color: #000;
  text-decoration: none;
  border-radius: 5px;
}


.servicebtn1 {
  display: block;
  padding: 10px;
  margin-bottom: 10px;
  color: #000;
  text-decoration: none;
  border-radius: 5px;
  background-color: #EEDE4F;
  font-size: 15px;

}
  
.servicebtn2 {
  display: block;
  padding: 10px;
  margin-bottom: 10px;
  color: #fff;
  border: 0px;
  font-size: 15px;
  text-decoration: none;
  background-color: red;
  border-radius: 5px;
}

.listhover  li {
  margin: 0px auto 200px auto;
}
#cta {
  padding: 0px 40px 0px 60px;
  
  background: #ECECEC;
}

.redcta {
  width: 24px;
    position: relative;
    top: 30px;
    left: -18px;
    height: 24px;
  background-color: red;
  border-radius: 50%;
  animation: zoomInOut 2s infinite; /* Adjust the animation duration as needed */
  color: white;

}


.owl-theme .owl-nav.disabled + .owl-dots{
  margin: 20px 0px;
}

.aboutdivcontent{
  padding: 50px 0px 50px 70px;
}
.headcontainercta{

  width: 100%;
  margin:20px auto 30px auto;
}

section .headtitlecta{
  color: #000;
  position: relative;

font-family: 'Poppins';
font-size: 23px;
font-style: normal;
font-weight: 600;
line-height: 40px;
margin: 15px auto!important;
}
/* .footerleft p{
  display: flex;
  align-items: start;
  justify-content: left;
} */
footer{
  padding: 60px 0px 10px 0px;
  /* background: linear-gradient(280deg, #000 -6.14%, #2C2C2C 74.24%); */
  background-color: #191919;
  position: relative;
    margin-top: -226px;
}

.leftfooter h4
{
  color: #FFF;

font-family: 'Poppins';
font-size: 30px;
font-style: normal;
font-weight: 700;
line-height: normal;

}
.leftfooter{
  color: #FFF;
font-family: 'Inter';
font-size: 16px;
font-style: normal;
font-weight: 400;
line-height: normal;
}
.leftfooterbottom{
  padding-top: 20px;
}

.footertopcircle{
  position: absolute;
  bottom: 20px;
}

.arrowfoter{
  position: relative;
  z-index: 999;
  width: 98%;
  margin-top: -4px;
}
.footercenter{
  margin-top: 30px;
}

.footercenter h5{
  color: #FFF;

font-family: 'Inter';
font-size: 22px;
font-style: normal;
font-weight: 600;
line-height: normal;
}

.footercenter  ul{
  list-style-type: none;
  padding: 0px;
}
.footercenter  ul li
{
  color: #FFF;
font-family: 'Inter';
font-size: 15px;
font-style: normal;
font-weight: 300;
padding: 0px 15px 0px 0px;
line-height: normal;
}





/* about */

@keyframes changeAngle{
  0%{
    background: conic-gradient(from 218deg at 79.31% 100%, #CA2529 0deg, #860003 360deg);
  }
  1%{
    background: conic-gradient(from 268deg at 79.31% 98%, #CA2529 0deg, #860003 360deg)
  }
  2%{
    background: conic-gradient(from 267deg at 79.31% 96%, #CA2529 0deg, #860003 360deg);
  }
  3%{
    background: conic-gradient(from 266deg at 79.31% 95%, #CA2529 0deg, #860003 360deg);
  }
  4%{
    background: conic-gradient(from 265deg at 79.31% 94%, #CA2529 0deg, #860003 360deg);
  }
  5%{
    background: conic-gradient(from 264deg at 79.31% 93%, #CA2529 0deg, #860003 360deg);
  }
  6%{
    background: conic-gradient(from 263deg at 79.31% 92%, #CA2529 0deg, #860003 360deg);
  }
  7%{
    background: conic-gradient(from 262deg at 79.31% 91%, #CA2529 0deg, #860003 360deg);
  }
  8%{
    background: conic-gradient(from 261deg at 79.31% 90%, #CA2529 0deg, #860003 360deg);
  }
  9%{
    background: conic-gradient(from 260deg at 79.31% 89%, #CA2529 0deg, #860003 360deg);
  }
  10%{
    background: conic-gradient(from 259deg at 79.31% 88%, #CA2529 0deg, #860003 360deg);
  }
  11%{
    background: conic-gradient(from 258deg at 79.31% 87%, #CA2529 0deg, #860003 360deg);
  }
  12%{
    background: conic-gradient(from 257deg at 79.31% 86%, #CA2529 0deg, #860003 360deg);
  }
  13%{
    background: conic-gradient(from 256deg at 79.31% 85%, #CA2529 0deg, #860003 360deg);
  }
  14%{
    background: conic-gradient(from 255deg at 79.31% 84%, #CA2529 0deg, #860003 360deg);
  }
  15%{
    background: conic-gradient(from 254deg at 79.31% 83%, #CA2529 0deg, #860003 360deg);
  }
  
}


.banner-hero-about{
  border-radius: 0px 0px 80px 0px;



/* background: conic-gradient(from 0.57turn at 65% 40%, #CA2529, 40deg, #860003, 320deg, #860003); */



/* background: conic-gradient(from 210deg at 79.31% 95%, #CA2529 0deg, #860003 360deg); */

background: conic-gradient(from 251deg at 79.31% 80%, #CA2529 0deg, #860003 360deg);
  height: 550px;
  width: 100%;
  animation: changeAngle 1s linear ;
}

.banner-hero-about p
{

  color: #FFF;

font-family: 'Poppins';
font-size: 25px;
font-style: normal;
font-weight: 200;
line-height: 33px;
margin: 29px 0px 68px 0px;
}

#aboutus-desc{
  padding: 50px 70px;
}
.Km-des-about h5
{
  color: #000;

font-family: 'Poppins';
font-size: 32px;
font-style: normal;
font-weight: 600;
line-height: 45px;
}
.KM-desc2-about1{
  overflow: scroll;
}
.KM-desc2-about h6
{
  margin-top: 36px;
  color: #000;
font-family: 'Poppins';
font-size: 25px;
font-style: normal;
font-weight: 300;
line-height: 40px; /* 114.286% */
}
.KM-desc2-about p{
  color: #000;
  font-family: 'Poppins';
  font-size: 20px;
  font-style: normal;
  font-weight: 300;
  line-height: 30px; /* 150% */
  margin-top: 30px;
}
.aboutus-img{
  width: 70%;
  margin: auto;
  display: block;
}
.Km-about-me{
  background: #ECECEC;
  padding: 50px 55px;
}
.about-me-capt h3{
  color: #000;
  font-family: 'Poppins';
  font-size: 32px;
  font-style: normal;
  font-weight: 600;
  line-height: 45px;
text-transform: capitalize;

}

.about-me-capt p{
  width: 95%;
  color: #151515;
  margin-top: 40px;
  font-family: 'Poppins';
  font-size: 28px;
  font-style: normal;
  font-weight: 300;
  line-height: 44px; /* 127.778% */
}

.about-me-capt b{
font-weight: 600;

}
.readmore{
  margin-left: 15px;
  font-family: 'poppins';
  font-weight: 600;
  margin-top: 5px;
  transition: all 0.8s ease; /* Transition for smooth animation */

}

.readmore:hover , .btnreadmore svg:hover + .readmore{
  color: red;
  margin-left: 25px;


}
svg{
  display: block;
}

.mv-line{
  height: 6px;
  width: 40px;
  background-color: #CA2529;
  margin-bottom: 25px;
}
.mv-title h5{
  color: #FFF;
font-family: 'Inter';
font-size: 40px;
font-style: normal;
font-weight: 600;
line-height: normal;
}
.mv-desc p
{
  color: #FFF;
  margin-top: 25px;
font-family: 'Poppins';
font-size: 25px;
font-style: normal;
font-weight: 300;
line-height: 35px; /* 121.875% */
}

/* banner */

.bannercaption {
  position: absolute;
  margin-left: 65px;
}
.navbar .dropdown1:hover .dropdown-menu {
  display: block;
}
.dropdown{
  border: 0px!important;
}
.bannercaption h2{
  color: #FFF;
  margin-top: 50px;
font-family: 'Poppins';
font-size: 44px;
font-style: normal;
font-weight: 700;
line-height: 55px; 
}

.bannercaption h3{
  color: #FFF;

font-family: 'Poppins';
font-size: 40px;
font-style: normal;
font-weight: 700;
line-height: 55px; 
}
.bannercaption h6
{
  color: #FFF;

font-family: 'Poppins';
font-size: 22px;
font-style: normal;
font-weight: 300;
line-height: normal;
letter-spacing: 1px;
}
.bannerul{
  list-style-type: none;
  margin-top: 34px;
  padding: 0px;
}
.bannerul li{
  color: #FFF;
  font-family: 'Poppins';
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-bottom: 20px;
}
.navbar-brand {
  width: 27%;
}
.bgimg::before{
  /* background-image: url("./yellowline.png"); */
  background-size: cover;
}

.prenav p{
  color: #000;
font-family: 'Poppins';
font-size: 14px;
font-style: normal;
font-weight: 300;
line-height: normal;
letter-spacing: 0.95px;
margin:0px 0px 0px 14px;
}
.topnavleft{
  justify-content: end;
}
.divider{
  background: rgba(0, 0, 0, 0.35);
  width: 95%;
  height:1px!important;
  margin:5px;
}

.footercircle{
  margin-top: 30px;
  width: 78px;
  height: 78px;
  border-radius: 40px;
  background-color: #ca2529;
  float: right;
  right: 20px;
  animation: moveUpDown 1s infinite alternate; /* 3s duration, infinite loop */  
}

@keyframes moveUpDown {
  0% {
    transform: translateY(0); /* Start at the bottom */
   
  }
  100% {
    transform: translateY(-40%); /* Move to the top */
  }
}

/* animation card section 2
 */

 .menu {
position: absolute;
	--marquee-width: 100vw;
	--offset: 20vw;
	--move-initial: calc(-25% + var(--offset));
	--move-final: calc(-50% + var(--offset));
	--item-font-size: 10vw;
}

/* Make sure 3 items are visible in the viewport by setting suitable font size. */

.marquee {
	top: 0;
	left: 0;
	width: var(--marquee-width);
	pointer-events: none;
  white-space: nowrap; /* Prevent text from wrapping */
  overflow: hidden; /* Hide any overflowing content */
  box-sizing: border-box;
}

.marquee__inner {
	width: fit-content!important;
	display: flex;
	position: relative;
	transform: translate3d(var(--move-initial), 0, 0);
	animation: marquee 10s linear infinite;
	animation-play-state: paused;
	opacity: 0;
	transition: opacity 0.1s;
  margin-top: 15px;
}
.strip{
  height: 4px;
  background: #CA2529;
  width: 60px;
  float: right;
  position: absolute;
  right: 0px;
  margin-top: -4px;
}
.strip-red{
  height: 4px;
  background: #CA2529;
  width: 53px;
  float: right;
  position: absolute;
  right: 6px;
  margin-top: -6px;
}
.marquee .marquee__inner {
	animation-play-state: running;
	opacity: 1;
	transition-duration: 1s;
}

.marquee span {
  color: rgba(217, 217, 217, 0.25);
font-family: 'Poppins';
font-size: 80px!important;
font-style: normal;
font-weight: 600;
line-height: normal;
	white-space: nowrap;
	font-size: var(--item-font-size);
	padding: 0 1vw;
	line-height: 1.15;
  text-transform: uppercase;
}

@keyframes marquee {
	0% {
		transform: translate3d(var(--move-initial), 0, 0);
	}

	100% {
		transform: translate3d(var(--move-final), 0, 0);
	}
}



/* home gency */

#hagency{
  background: #ECECEC;
  padding: 50px 50px!important;
  margin-top: 10px;
}
.hagency-km-header h4
{

  color: #000;
font-family: 'Poppins';
font-size: 40px;
font-style: normal;
font-weight: 600;
line-height: 45px; /* 112.5% */
position: relative;
z-index: 0;
}
.hagency-km-header p

{
  width: 100%;
  margin-top: 40px;
  color: #000;
font-family: 'Poppins';
letter-spacing: 0.8px;
font-size: 26px;
font-style: light;
font-weight: 300;
line-height: 40px; /* 117.647% */
}
.hagency-km-header span
{
  color: #000;
font-family: Poppins;
font-size: 26px;
font-style: normal;
font-weight: 500;
line-height: 40px;
}
.about-me-capt span{
  color: #000;

}
.km-bold{
  font-weight: 500;
}

.strip2{
  height: 4px;
  background: #CA2529;
  width: 55px;
  float: right;
  position: absolute;
  /* margin-top: 9px; */
}

.contact-strip2{
  height: 4px;
    background: #CA2529;
    width: 82px;
    float: right;
    position: absolute;
    margin-top: -60px;
}
.strip3{
  height: 4px;
  background: #CA2529;
  width: 65px;
  float: right;
  position: absolute;
  margin-top: -3px;
}



/* company */

#company-km {
padding: 0px;
  background: #ECECEC;
}
.blacksection{
  margin: 0px 40px 0px 0px;
  border-radius: 0px 120px 0px 0px;
  background: #151515;
  padding: 50px 50px 50px 50px;
}

video{
  border-radius: 10px;
}

/* comapy */

.company-caption h3{
  color: #FFF;
  font-family: 'Poppins';
  font-size: 38px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-transform: capitalize;
}

.btn-company{
  display: flex;
  margin-top: 30px;
}
.company-caption p{

  color: #FFF;
  width: 94%;
font-family: 'Poppins';
font-size: 17px;
font-style: normal;
font-weight: 300;
line-height: normal;
margin-top: 30px;
}

.btncompany1{
  border-radius: 0px 15px 0px 0px;
border: 1.5px solid #FFF;
background: rgba(238, 222, 79, 0.00);
color: #FFF;
font-family: 'Inter';
font-size: 14px;
font-style: normal;
font-weight: 600;
line-height: normal;
display: flex;
padding: 10px 15px;

align-self: center;
}
.btncompany1:hover{
  background: rgba(238, 222, 79, 0.00);
  border: 1.5px solid #FFF;
}
.btncompany2{
padding: 10px 15px;

  align-items: center;
  align-self: center;
  display: flex;
  border-radius: 0px 15px 0px 0px;
background: #CA2529;
color: #FFF;
font-family: 'Inter';
font-size: 14px;
border: 0px;
font-style: normal;
font-weight: 600;
line-height: normal;
margin-left: 30px;
border: 1.5px solid #CA2529;
}
.btncompany2:hover{
  background: #CA2529;
  border: 1.5px solid #CA2529;
}
.btncompany1:hover{
  background: rgba(238, 222, 79, 0.00);
  border: 1.5px solid #FFF;
}
.company-km-img

{
  width: 89%!important;
  display: block;
}

.km-spacing-company{
  margin-bottom: 60px;
}


.title-box {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  border-radius: 0px 60px 0px 0px;
  backdrop-filter: blur(8px);
  padding: 20px 20px 10px 30px;
}
.Media-reel-box {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  border-radius: 0px 0px 0px 0px;
  backdrop-filter: blur(8px);
  padding: 20px 20px 10px 30px;
}
.Media-reel-box h4{
  color: hsl(0, 0%, 100%);
  font-family: 'Poppins';
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 21px; /* 105% */
  }
.title-box h4{
color: hsl(0, 0%, 100%);
font-family: 'Poppins';
font-size: 18px;
font-style: normal;
font-weight: 500;
line-height: 21px; /* 105% */
}
.Media-reel-box h4{
color: hsl(0, 0%, 100%);
font-family: 'Poppins';
font-size: 17px;
font-style: normal;
font-weight: 500;
line-height: 21px; /* 105% */
}

.item__overlay1 h4 {
  margin: 0;
	display: block;
  font-family: 'Poppins';
  line-height: 28px !important;
  font-size: 22px;
}
.item__body p{
  color: white;
  margin-top: 20px;
  font-size: 18px;
  font-family: 'Poppins';

}
	
	.hovercard {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		z-index: 1;
    border-radius: 0px 25px 0px 0px;
		
    
		&:hover,
		&:focus {
			~ .item__overlay1 {
				transform: translate3d(0, 0, 0);
        padding-top: 70px;
        
			}
      ~ .item__overlay1 h4{
        font-weight: 500;
      }
		}
	}

.item1 img {
	width: 100%;
	height: 100%;
	display: block;
	object-fit: cover;
}
.item1 a:hover{
  border-radius: 0px 20px 0px 0px;
  font-size: 15px;
}
.item__overlay1 {
	display: flex;
	flex-direction: column;
	justify-content: center;
	/* height: 100%; */
	position: absolute;
	width: 100%;
	top: 0;
	transition: transform 300ms;
	background-color: lighten(turquoise, 15%);
	transform: translate3d(0, calc(100% - 5.5rem), 0);
}

.item__body {
	flex-grow: 1;
}
.silder2{
  padding: 10px 30px;
}

.owl-dots .active{
  color: #CA2529!important ;
}

/* insight   */
.owl-dots span{
  margin: 1px 3px!important;
  fill: #D9D9D9;
}
.owl-carousel .owl-stage-outer {
  position: relative;
  overflow: hidden;
  -webkit-transform: translate3d(0px, 0px, 0px);
}

.owl-dots .active  span{
  background: #CA2529!important;
}




.circle {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  background-color: #CA2529;
  bottom: 20px;
  float:right;
  transition: transform 0.5s ease-in-out;
}
.circle.zoom {
  animation: zoomAnimation 1s ease-in-out;
}

@keyframes zoomAnimation {
  0%, 100% {
    transform: translateX(-50%) scale(1); /* Initial and final scale */
  }
  50% {
    transform: translateX(-50%) scale(1.2); /* Zoom in */
  }
}


/* team */

.card {
  margin-bottom: 20px;
  transition: .5s ease;
}

/* .card:hover .card-details {
		position: absolute;
		left: 0;
		width: 100%;
		height: 100%;
    bottom: 0;
		z-index: 0;
    padding-top: 50px;
    transition:.5s ease; /* Adjusted transition duration to 3 seconds */

    		
		/* &:hover,
		&:focus {
			~ .card-details{
    -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  text-align: center;
			}
      .card-details .para{
        pointer-events: inherit;
      }
		}
    .box {
      width: 200px;
      height: 50px;
      background-color: #3498db!important;
      color: #fff;
      text-align: center;
      line-height: 50px;
      cursor: pointer;
      transition: transform 3s ease-in-out; Adjusted transition duration to 3 seconds
  } */
  
  /* .box:hover {
      transform: translateY(-10px);
  } */


    /* .social-logo{
      margin: 0;
      padding-top: 20px;
      opacity: 100%;
    }
    .para p{
      padding-top: 20px;
      margin: 0px;
      color: #FFF;
      font-family: 'Poppins';
      font-size: 16px;
      font-style: normal;
      font-weight: 200;
      opacity: 100%;
    } */
  /* transform: translateY(-100%); */
  
.social-logo{
  margin: 0;
  padding-top: 30px;
  opacity: 0;
}

.card-details {
  padding: 10px 20px;
  border-radius: 0px 30px 0px 0px;
  background: rgba(0, 0, 0, 0.12);
  backdrop-filter: blur(30px);
    color: white;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 20%;
  transition: transform 0.3s ease;
  
}
.card-details-about{
  padding: 20px 20px;
  border-radius: 0px 0px 20px 20px;
  background: rgba(0, 0, 0, 0.12);
  backdrop-filter: blur(30px);
  color: white;
  position: absolute;
  top: 135%;
  right: 13%;
  width: 18.3%;
  /* bottom: 1%; */
  height: 13%;
  transition: transform 0.3s ease;
}
.card-details h5
{
  color: #FFF;
font-family: 'Poppins';
font-size: 20px;
font-style: normal;
font-weight: 500;
margin: 0px;
text-transform: capitalize;
}

.para p{
  padding-top: 30px;
  margin: 0px;
color: #FFF;
  font-family: 'Poppins';
  font-size: 18px;
  font-style: normal;
  /* font-weight: 400; */
  opacity: 0;
}
.social-logo  i{
  font-size: 38px;
}

.aboutus-img{
  width: 80%;
}
.card-details .p1{
  
margin:5px auto;
color: #FFF;
  font-family: 'Poppins';
  font-size: 14px;
  font-style: normal;
  font-weight: 400;

  text-transform: capitalize;
}
.stripteam{
  height: 2.7px;
  margin-top: 2px;
  background-color: #fff;
  width: 50px;
  padding: 5px auto;
}
/* Add more styles as needed */
/* about */
.banner-hero-about h2{
  color: #FFF;
font-family: 'Poppins';
font-size: 50px;
font-style: normal;
font-weight: 500;
line-height: 55px; /* 110% */
}
.bannerul li {
  position: relative;
}

.bannerul li span {
  color: #fff;
  position: relative;
  left: 0;
  font-size: 18px!important;
  transition: margin-left 0.3s ease; /* Smooth transition for left property */
}

.bannerul li:hover span {
  margin-left: 10px; /* Move the span 50px to the left on hover */
}

.svg-arrow{
  float: right;
}



/* contcat us */
.contact-form{
  background-color: #151515;
  background-size: cover;
  padding: 250px  50px 35px 50px;
  margin-top: -15%;
  z-index: 0;
}
.contacttitle h4{
  margin-bottom: 65px;
  color: #FFF;
font-family: 'Poppins';
font-size: 40px;
font-style: normal;
font-weight: 500;
line-height: normal;
}
 .contact-form input, .contact-form textarea{
  color: #fff!important;
  padding: 14px 20px!important;
  margin: 10px 20px 10px 0px;
  width: 100%;
  border-radius: 0px!important;
  border: 1px solid rgba(255, 255, 255, 0.20)!important;
background: linear-gradient(270deg, #070707 1.74%, rgba(95, 95, 95, 0.00) 99.76%)!important;
}
::placeholder
{
  color: #FFF!important;
font-family: 'Poppins';
font-size: 16px;
font-style: normal;
font-weight: 300;
line-height: normal;
letter-spacing: 0.4px;
}

.submitbtn button{
  border-radius: 0px 20px 0px 0px;
background: #CA2529;
margin: auto;
display: block;
margin-top: 40px;
border: 0px;
color: #FFF;
font-family: 'Poppins';
font-size: 15px!important;
font-style: normal;
font-weight: 500;
line-height: normal;
padding: 15px 27px;
letter-spacing: 1.2px;

}

.bannercontact{
  background: #ECECEC;
}
.contact-right{
  border-radius: 0px 45px 0px 0px;
background: #CA2529;
padding:20px;
margin: 20px;
position: relative;
}
.contact-right h6
{
  color: #FFF;
font-family: 'Poppins';
font-size: 20px;
font-style: normal;
font-weight:500;
line-height: normal;
margin-bottom: 10px;
}
.any-request h4{
  color: #FFF;
  font-family: 'Poppins';
  font-size: 27px;
  font-style: normal;
  font-weight:600;
  line-height: normal;
}
.contact-right ul{
  list-style-type: none;
}
.contact-right li{
  color: #FFF;
font-family: 'Poppins';
margin-top: 22px;
font-size: 16px;
font-style: normal;
font-weight: 300;
line-height: normal;
}
.contatclefttext p
{
  color: #000;
font-family: 'Poppins';
font-size: 20px;
font-style: normal;
font-weight: 300;
line-height: normal;
width: 90%;
margin-top: 40px;
}


.contatclefttext h4
{
  color: #000;
font-family: 'Poppins';
font-size: 42px;
font-style: normal;
font-weight: 500;
line-height: 50px;
letter-spacing: 1px;
}


/*Footer Done*/
.first-column-footer ul{
  padding:0px 10px!important;
}
.first-column-footer h6{
  color: '#fff';
  font-family: 'Inter';
  font-size: 22px;
  font-weight: 400;
}

.first-column-footer p{
  color: #fff!important;
  font-family: 'Inter';
  font-size: 17px;
  font-weight: 400;
}

.footerleft h5{
  color: #fff;
  font-size: 34px;
  font-weight: bold;
  font-family: 'Inter';
  letter-spacing: 1px;
}

.footerleft p{
  color: #fff;
    font-size: 16px;
    font-family: 'Poppins';
    font-weight: 300;
}
.footerleft1 h5{
  color: #fff;
  font-size: 27px;
  font-weight: 600;
  font-family: 'Poppins';
}

.footerleft1 p{
    color: #fff;
    font-size: 17px;
    font-family: 'Poppins';
    font-weight: 300;
}
.footerright h5{
  color: #fff;
  font-size: 22px;
  font-weight: 500;
  font-family: 'Poppins';
  margin-bottom: 15px;
}
.footerright ul{
  padding: 0px;
  margin: 0px;
}
.footerright ul li{
  color: #fff;
    text-wrap: nowrap;
    font-weight: 400;
    margin-right: 25px;
    list-style-type: none;
    font-size: 15px;
    font-family: 'Poppins';
    width: fit-content;
    z-index: 9999;
  
}
.footer-space{
  margin-bottom: 30px;
}


/* Adjust the width of the carousel container to fit 1.5 items */
.owl-carousel .owl-stage {
  width: 120%; 
}

/* Ensure the first item is completely visible */
.owl-carousel .owl-item:first-child {
  margin-right: 5px; /* Adjust margin if needed */
}

/* Adjust the margin between items */
.owl-carousel .owl-item:not(:first-child) {
  margin-left: 0px; /* Adjust margin if needed */
}

/* Adjust the width of the second item */
.owl-carousel .owl-item:nth-child(2) {
  width: 20%; /* Second item takes 50% of the container width */
}


/*About us*/
.bloggrid{
  display: flex;
  flex-wrap: wrap;
  padding: 20px 0px;
}
.imgblog{
  padding: 30px 10px;
}

.blogdivider{
  margin: 20px auto;
  border:1px solid rgba(0, 0, 0, 0.20);
}
.blogtitle p
{
  padding: 15px 0px;
  color: #000;
font-family: 'Poppins';
font-size: 22px;
font-style: normal;
margin-bottom: 0px;
font-weight: 500;
line-height: 35px; /* 113.333% */
stroke-width: 1px;
}
.blacksectionabout{
  border-radius: 0px 120px 0px 0px;
background: linear-gradient(180deg, #151515 0%, #202020 100%);
}

.blacksectionabout  h5 , .blacksectionabout  p, .blacksectionabout h6{
  color: #fff!important;
}












































































.blacksectionabout  p
{
  color: #FFF;
font-family: 'Poppins';
font-size: 20px;
font-style: normal;
font-weight: 200;
line-height: 30px;
margin-top: 20px;
}
.blacksectionabout{
  padding: 50px 70px;
}
.Km-mission-vision{
  background-color: #ECECEC;
}
/* cta btb */
.button-cta{
  border-radius: 0px 15px 0px 0px;
background: #CA2529;

border: 0px;
color: #FFF;
font-family: 'Inter';
font-size: 15px;
font-style: normal;
padding: 12px 14px;
font-weight: 600;
line-height: normal;
}


.button-cta {
  position: relative;
  overflow: hidden;
  color: white; /* Set your button text color */
  border: none;
  padding: 10px 18px;
  cursor: pointer;
  font-size: 16px;
  border-radius: 0px 15px 0px 0px;
}
.button-cta svg {
  transition: transform 0.3s ease-out;
}

h5 svg{
  margin-left: -30px;
}

.button-cta:hover svg {
  transform: translateX(10px); /* Adjust the value based on your preference */
}
.button-cta:before {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 0;
  height: 0;
  box-shadow: 0 0 10px rgba(255, 255, 255, 0.3); /* Adjust the opacity as needed */

  border-radius: 50%;
  transition: width 0.3s ease-out, height 0.3s ease-out;
}

.button-cta:hover:before {
  width: 200px; /* Adjust the circle size based on your preference */
  height: 200px; /* Should be equal to the width for a perfect circle */
}


.mega-menu 
{
  padding: 40px 40px 10px 40px!important;
}
.mega-div{
  padding-right:18px;
}
.mega-div p{
color: #000!important;
font-weight: 600;
font-size: 14px;
margin-top: 20px;
line-height: 25px;

}
.mega-menu h4
{
font-size: 28px;
color: #000;
font-weight:600;
font-family: 'poppins';
}


.mega-menu h6
{
font-size: 18px;
font-weight: 500;
color: #000;
font-family: 'poppins';
}

.mega-menu img{
  width:98%;
  margin: auto;
  display: block;
}
.mega-menu p
{
font-size: 15px;
font-weight: 400;
font-family: 'Poppins';
color: #000;
margin: 0px;
}
.nav-item:hover .mega-menu {
  display: block;
  transition: all 0.3s ease;

}

.mega-menu {
  display: none;
  position: fixed;
  left: 0;
  top: 100%; 
  transition: all 0.3s ease;
  background-color: #fff;
  width: 100%;
  padding: 20px;
  z-index: 999;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); 
} 



.navbar-nav .nav-link.active .upper-line {
  height: 2px; /* Set the height to 2 pixels */
  /* Add any other styles you want to apply to the upper line */
}


/* Add this CSS to your stylesheet */
.navbar-nav .nav-link.active {
  /* font-weight: bold;
   Optional: Adjust font weight for emphasis */
  /* Add any other styles you want to apply to the active link */
} 



 /* CSS styles for SVG */
 .line {
  transition: all 1s ease;
  stroke-width: 3;
}

.arrow__animate {
  display: inline-block;
}

.arrow__animate.left {
  transform: rotate(180deg);
}
.arrow__animate svg {
  width: 200px;
  transform: translateX(0);
  transition: 0.5s;
}
.arrow__animate svg.variant1 {
  transform: translateX(-25%);
}
.arrow__animate svg rect {
  transform: scale(0.75, 1);
  transition: 0.5s;
  transform-origin: right;
}
.arrow__animate svg:hover {
  transform: translateX(25%);
}
.arrow__animate svg:hover rect {
  transform: scale(1, 1);
}
.arrow__animate path,
.arrow__animate rect {
  transition: 0.5s;
}





 /* Style the dropdown container */
 .dropdown {
  display: inline-block;
}

/* Style the dropdown button */
.dropbtn {
  /* background-color: #333; */
  background-color: white;
  color: black;
  padding: 10px;
  font-size: 25px;
  border: none;
  padding-right: 30px;
  cursor: pointer;
}

/* Style the dropdown content */
.dropdown-content {
  display: none;
  position: absolute;
  background-color: #f9f9f9;
  min-width: 160px;
  box-shadow: 0 8px 16px rgba(0,0,0,0.2);
  z-index: 1;
  right: 5%;
}

/* Style the dropdown items */
.dropdown-content a {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}

/* Change color on hover */
.dropdown-content a:hover {
  background-color: #ddd;
}

/* Show the dropdown menu when hovering over the dropdown button */
.dropdown:hover .dropdown-content {
  display: block;
}








/*Media Query*/
/*Media Query*/

@media screen and (max-width: 468px){
  .bannercaption h2 {
    font-size: 18px !important;
 
}
}
@media screen and (max-width: 768px){

  
  html, body{
    margin: 0;
    padding: 0;
  }
  ul li a, .navbar-nav .nav-link{
    padding: 4px 24px 4px 10px!important;
  }
  .bannercaption {
    position: absolute;
    margin-left: -10px;
    padding: 10px;
  }
  dropdown-menu {
    display: none;
    padding: 10px;
    background-color: #f9f9f9;
    position: absolute;
    top: 100%;
    left: 0;
    z-index: 1000;
  }
  
  .dropdown-item {
    display: block;
    padding: 5px 10px;
    color: #333;
    text-decoration: none;
  }
  
  
  .nav-link{
    font-weight: 500!important;
  }
  .arrow {
    display: inline-block;
    width: 0;
    height: 0;
    border-left: 6px solid transparent;
    border-right: 6px solid transparent;
    border-top: 6px solid white;
    position: absolute;
    top: 50%;
    right: 10px;
    transform: translateY(-50%);
    transition: transform 0.3s;
  }
  
  .open .arrow {
    transform: translateY(-50%) rotate(180deg);
  }
  #footer1{
    padding: 20px 5px 0px;
  }
  .privacy1{
    padding: 20px 10px!important;
  }
  .privacy1 h4{
    font-size: 22px;
  }
  .privacy1 h4{
    font-size: 18px;
  }
  .img-blog  img{
    margin: auto;
    display: block;
  }
  .navbar-nav a:after {
    display: none;
    content: '';
    position: absolute;
    top: 0;
    margin-top: -11PX;
    left: 50%; /* Adjust the left position to center the line */
    transform: translateX(-50%); /* Center the line horizontally */
    width: 50%; /* Set the width to 50% or adjust as needed */
    height: 4px;
    background-color: transparent; /* Set your initial color for the upper line */
    transition: width 0.3s ease, background-color 0.3s ease; 
  }
  .dropdown-menu{
    padding: 0px;
  }
  .nav-arrow img{
    width: 90%;
  }
  .banner-hero-about p {
    margin: 29px 0px 35px 0px;
  }
  .upper-line {
    display: none; /* Hide the upper-line div since we're using :after for the border */
  }
  
  .navbar-nav a:hover .upper-line,
  .navbar-nav a.active .upper-line {
    display: none; /* Make sure it's displayed when needed */
  }
  .navbar-nav a.active:after {
    width: 0%;
    background-color: red;
  }
  .Media-reel-box {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    border-radius: 0px 0px 0px 0px;
    backdrop-filter: blur(8px);
    padding: 20px 20px 10px 30px;
  }
  .title-box{
    border-radius: 0px 30px 0px 0px;
  }

  #person1{
    font-size: 14px;
  }
  .btn-company a{
    font-size: 15px;
  }
  .strip-red{
    height: 4px;
    background: #CA2529;
    width: 70px;
    float: right;
    position: absolute;
    right: 32px;
    margin-top: -4px;
  }
  .cardwithimage img{
    height: 0px;
  }
  nav {
    margin: 15px 0px 0px 20px;
    border-radius: 0px 20px 0px 0px;
    width: 90%;
    padding: auto 30px !important;
  }
  .navbar-expand-lg{
    padding: 10px 0px;
  }
  .navbar-brand {
    margin: 0px;
    width: 120px;
  }
footer{
  margin-top: auto!important;
}
section .headtitlecta{
  font-size: 20px!important;
  font-weight: 600;
  line-height: 28px;
  
}
.headtitlecta{
 
  font-size: 30px;
}
  .bannercaption h6{
    margin: 0;
  }
  .bannerul img{
    display: none;
  }
  .bannerul li{
    text-align: center;
    font-size: 15px;
  }
  .extraclass{
    margin-left: 0px;
  }
.hagency-km-header h4{
  font-size: 24px;
}

.hagency-km-header p{
  width: 100%;
  margin-top: 40px;
  font-size: 16px;
  letter-spacing: 0.3px;
  line-height: 26px;
  justify-content: center;
}
.hagency-km-header span
{
font-size: 16px;
line-height: 20px;
}

.company-caption h3{
  margin-top: 12px;
  text-align: left;
  font-size: 26px;
}

.company-caption p{
  width: 100%;
font-size: 16px;
margin-top: 30px;
}
#cta {
  padding: 0px 0px 25px 30px;
}
.headcontainercta {
  width: 100%;
  margin: 0px auto 20px auto;
}
.button-cta{
  margin-top: 20px;
}
#hagency{
  padding: 25px 10px!important;
}

.red-circle-ag {
  display: none;
  width: 24px;
  position: relative;
  top: 15px;
  right: 22px;
  height: 24px;
  background-color: red;
  border-radius: 50%;
  animation: zoomInOut 4s infinite;
  color: white;
  transform-origin: top left;
}
.any-request {
  padding: 0px;
}
.blacksection{
  border-radius: 0px 50px 0px 0px;
  background: #151515;
  padding: 20px 22px;
  margin-right: 20px;
}
figure{
  margin: 10px 0px 0px 0px!important;
}
.km-spacing-company{
  margin-bottom: 10px;
}

.company-km-img{
  width: 100%!important;
  display: block;
}
.km-spacing-company .row{
  flex-direction: column-reverse;
}

.d-sm-none{
  display: none!important;
}
.strip2{
  width: 60px;
  margin-top: 0px;
}


.strip3{
  height: 4px;
  background: #CA2529;
  width: 45px;
  float: right;
  position: absolute;
  margin-top: -3px;
}
.btncompany2, .btncompany1{
  font-size: 12px;
  
}
.btncompany2{
  margin-left: 18px!important;
}

/* logo */
.navbar-brand{
  width: 55%!important;
}
.navbar-toggler{
  border: 0px!important;
}
.navbar-toggler-icon{
  background-image: url('./bar2.png');
  width: 30px!important;
  border: 0px!important;
}
/* banner */
.bannercaption h2{
  font-size: 22px!important;
  line-height: 26px!important;
  margin-top: 0px!important;
}
.band
.bannercaption{
  margin: 8px;

}
.title-box{
  padding: 10px 20px;
}
.bannercaption h6{
  text-align: center;
  font-size: 15px!important;
  margin-top: 20px;
  /* letter-spacing: 0.px; */
}
.btncompany2:hover{
  background: #CA2529;
  border: 1.5px solid #CA2529;
}
.nav-text h6
{
  color: #000;
font-family: 'Poppins';
font-size: 10px;
font-style: normal;
font-weight: 500;
line-height: 11px; /* 110% */
position: relative;
top: 7px;
right: 100px;
}
.cls{
  margin-left: 12px;
  text-align: left;
  position: absolute;
  bottom: 0px;
}
.line{
  height: 20px;
  width: 2px;
  background-color: #000;
  position: absolute;
  bottom: 0px;
}
.bannercaption h3{
  font-size: 15.5px!important;
    font-weight: 600!important;
    margin-top: 15px!important;
    line-height: 20px!important;
}
.bannerimgleft{
  height: 400px;
}
b{
  font-weight: 500;
}
section .headtitle{
font-size: 28px!important;
font-style: normal;
font-weight: 600;
line-height: normal;
/* margin: 10px auto; */
}
.red-circle {
  width: 22px;
  position: relative;
  top: 20px;
  /* z-index: -1; */
  right:22px;
  height: 22px;
  background-color: red;
  border-radius: 50%;
  animation: zoomInOut 2s infinite; /* Adjust the animation duration as needed */
  color: white;
}
.red-circle1{
  width: 22px;
  position: relative;
  top: 25px;
  /* z-index: -1; */
  right: 5px;
  height: 22px;
  background-color: red;
  border-radius: 50%;
  animation: zoomInOut 2s infinite; /* Adjust the animation duration as needed */
  color: white;
}
.red-circle-apply{
  width: 22px;
  position: relative;
  top: 28px;
  /* z-index: -1; */
  height: 22px;
  background-color: red;
  border-radius: 50%;
  animation: zoomInOut 2s infinite; /* Adjust the animation duration as needed */
  color: white;
}
/* .title-box h4{
  font-size: 16px;
  margin: 0;
  
  /* font-weight: 600; }*/

/* .title-box{
  padding: 12px;
  text-align: left;
}  */
.silder2{
  padding: 0px 0px;

}
.sec-menu .owl-stage-outer {
  height: 240px;
}
.sec-menu{
  margin-bottom: 0px;
}
.owl-dots{
  display: none;
}
.bannerul li {
  position: relative;
  margin-bottom: 6px;
}


/*Aboutus*/
.banner-hero-about h2{
  font-size: 24px;  
  text-align: left;
  padding: 150px 0px 0px 10px;
  line-height: 30px;
  font-weight: 600;
}
.banner-hero-about p{
  font-size: 15px;
  font-weight: 400;
  line-height: 22px!important;
} 
.aboutdivcontent{
  padding: 150px 10px 60px 10px;
}
.banner-hero-about h2{
  padding: 0px;
}
.banner-hero-about{
  height: 450px;
  border-radius: 0px 0px 70px 0px!important;
} 
#aboutus-desc{
  padding: 50px 20px;
}
.Km-about-me{
  padding: 30px 20px;
}
.Km-des-about h5{
  font-size: 28px;
  margin-bottom: 13px;
}
.bgmate{
  margin-right: 20px;
}
.KM-desc2-about{
  margin-top: 20px;
}
.bgmate{
  padding:20px;
}
.aboutus-img{
  width: 94%;
}
.Km-des-about h6{
  font-size: 17px;
  line-height: 22px;
  margin-top: 35px;
  margin-bottom: 0px;
}
.KM-desc2-about p{
  font-size: 18px;
  line-height: 25px;
  margin-bottom: 35px;
}
.about-me-capt h3{
  font-size: 18px;
  line-height: 22px;
  font-weight: 600!important;
  text-align: left;
  margin-bottom: 12px;
}
.about-me-capt p{
  width: 100%;
  font-size: 15px;
  line-height: 22px;
  font-weight: 300;
}
.Founder p{
  font-size: 16px;
  line-height: 22px;

}
.Founder h5{
  font-size: 18px;
}
.Founder h6{
  font-size: 16px;
  margin-bottom: 20px;
}
.founderimg {
  border-radius: 0px 30px 0px 0px;
  width: 100%;
  margin: auto;
  display: block;
}
.navbar-nav li{
  width: 100%;
}
.blacksectionabout{
  padding: 30px 20px 20px 20px!important;
  border-radius: 0px 30px 0px 0px!important;
  background: linear-gradient(180deg, #151515 0%, #202020 100%);
}

.blogtitle p{
  font-size: 20px;
  line-height: 25px;
}


.loadmore h5{
  font-size: 18px;
  font-weight: 400;
  text-align: center;
}
.KM-desc2-about p{
  font-size: 15px;
  font-weight: 300;
  line-height: 22px; /* 150% */
  margin-top: 30px;
  text-align: left;
}

ul li a,.navbar-nav .nav-link{
  padding:12px 15px!important;
  color: #000;
font-family: 'Poppins'!important;
font-size: 15px!important;
font-style: normal;
font-weight: 400;
line-height: normal;
}
.footerleft1 h5{
  color: #fff;
  font-size: 24px;
  font-weight: 600;
  font-family: 'Poppins';
  margin-bottom: 0;
  padding-top: 30px;
}
.any-request h4{
  font-size: 22px;
}
.contatclefttext h4{
  font-size: 30px;
  /* padding-left: 10px; */
  margin-top: 140px;
  line-height: 32px;
}
.contatclefttext p{
  padding-top: 20px;
  font-size: 18px;
  padding-bottom: 55px;
}
.contact-form{
  background-image: url('./mobcontactimg.png');
  background-size: cover;
  padding: 90px 0px 30px 0px;
}


  .contact-top  .strip2 {
height: 5px;
}
.contatclefttext h4{
  font-weight: 600;
  font-family: 'poppins';
  line-height: 36px;
  margin-bottom: 20px;
}
.contact-right li{
  margin-top: 10px;
}
.contact-right{
border-radius: 0px 60px 0px 0px;
}
.submitbtn button{
  margin-top: 25px;
  font-size: 14px;
}
.contacttitle h4{
font-size: 22px;
font-style: normal;
font-weight: 500;
line-height: normal;
} 
.contact-right{
  margin: 0px;
  position: static;
}
.concttext{
  padding-left: 25px;
}
.contact-top{
  padding: 0;
}
.nav-item{
  border-bottom: 0.5px solid black!important;
}
.txt{
  font-size: 19px;
  font-family: "poppins";
}
.footerright h5{
  color: #fff;
  font-size: 24px;
  font-weight: 600;
  padding-top: 12px;
  font-family: 'Poppins';
  margin-bottom: 25px;
  line-height: 17px;
}
.footerright ul li{
  font-size: 16px;
  font-family: 'Poppins';
  color: white;
  padding-bottom: 8px;
  font-weight: 300;
}
.footerleft{
  padding-top: 20px;
}
.footerleft p{
   display: flex;
    align-items: end;
    justify-content: center;
    border-top: 1px solid white;
    padding-top: 15px;
}
footer {
  padding: 38px 0px 0px 8px;
}
.title-box{
  background-color: lightblack;
  backdrop-filter: blur(1px);

}
.item__body p{
  font-size: 15px;
}
.item__overlay1 {
	display: flex;
	flex-direction: column;
	justify-content: center;
	height: 120%;
	position: absolute;
	width: 100%;
	top: 0;
	transition: transform 300ms;
	transform: translate3d(0, calc(100% - 6.5rem), 0);
}
.item__overlay1 h4 {
  margin: 0;
	display: block;
  font-family: 'Poppins';
  line-height: 20px !important;
  font-size: 14px;
  padding: 0;
}
.footerleft1 p{
  color: #fff;
  font-size: 17px;
  font-family: 'Poppins';
  font-weight: 300;
  line-height: 50px;
}
.hovercard {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  
  &:hover,
  &:focus {
    ~ .item__overlay1 {
      transform: translate3d(0, 0, 0);
      padding-top: 50px;
    }
    ~ .item__overlay1 h4{
      font-weight: 600;
    }
  }
  
  
}
.item1 a:hover{
  border-radius: 0px 15px 0px 0px;
}
.strip{
  height: 4px;
  background: #CA2529;
  width: 43px;
  float: right;
  right: 0%;
  position: absolute;
  margin-top: -6px;
  }
  .marquee span {
    color: rgba(217, 217, 217, 0.25);
  font-family: 'Inter';
  font-size: 26px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
    white-space: nowrap;
    font-size: var(--item-font-size);
    padding: 0 1vw;
    line-height: 1.15;
    text-transform: uppercase;
  }
  .Km-des-about h5 {
    font-size: 20px!important;
    line-height: 25px;
}
.Km-des-about p {
  font-size: 15px!important;
}
.bgmate h6 {
  font-size: 18px!important;
  margin-top: 10px;
}
.bgmate{
  background-color: #151515 ;
  /* padding: 40px 50px 40px 30px;
  margin: 0px -70px 0px 0px; */

  border-radius: 0px 50px 0px 0px;
}
.instabtn {
  margin-top: 10px;
  width: 100%;
  text-align: center;
  padding: 10px 15px;
  background-color: #fff;
  border-radius: 0px 30px 0px 0px;
  color: #8c0307;
  font-size: 15px;
  font-weight: 500;
  float: left;
  transition: background-color 0.5s;
}
.instagramstrip {
  margin-bottom: 20px!important;
}
}

@media only screen and (max-width: 768px) {
  .submitbtn button{
    font-size: 15px!important;
    padding: 10px 15px;
  }
  .bannerul li span{
    font-size: 14px!important;
  }
  .bannerul a {
    padding: 0px;
  }
  .bannerul{
    margin-left: -25px;
  }
  .aboutleftimg video{
    width: 100%;
  }
  .footer-space{
    margin-bottom: 4px;
  }
  .blacksectionabout p{
    line-height: 24px;
  }
  .contact-right li{
    font-size: 15px;
  }
  .contact-right h6{
    font-size: 18px;
  }
  .custom-modal {
    background-color: #000;
    border-radius: 8px;
    padding: 20px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
    max-width: 96%;
    max-height: max-content;
    overflow: auto;
    position: relative;
    border-radius: 0px 30px 0px 0px;
}
  .contact-right{
    margin: 15px 10px!important;
  }
  .headcontainer {
    width: fit-content;
    margin: 0px auto 30px auto;
}
h2{
  color: #FFF;
    padding-top: 120px;
    text-align: center;
    font-family: "Poppins";
    font-size: 35px;
    font-style: normal;
    font-weight: 700;
    line-height: 52px;
}
h3 {
  color: #F5F5F5;
  font-family: "Poppins";
  font-size: 25px;
  font-style: normal;
  text-align: center;
  font-weight: 700;
  line-height: 34px;
  text-transform: uppercase;
}

.banner-hero {
  height: auto;
}
.btnarea {
  padding-top: 30px;
  padding-bottom: 60px;

}
h6{
  font-size: 20px;
}
.card{
  margin-bottom: 20px;
}
.blog-img{
  width: 100%;
  height: 40vh;
  padding-top: 15px;
}
.blog-head h4{
  font-size: 22px;
  font-family: 'Poppins';
  font-weight: 600;
}
.blog-head{
  padding:80px 0px 0px 0px;
}
.privacy{
  padding: 20px 10px 20px 10px;
}
.bord-left{
  border-right: 0px solid #202020;
}
.bloggrid1{
  padding: 5px 5px 30px 5px;
}
.four-img{
  /* padding-left: 15px; */
}
.blog-red-circle {
 
  top: 6px;
  /* z-index: -1; */
  right: 22px;
  
}
.blog-strip{
  height: 4px;
  background: #CA2529;
  width: 45px;
  float: right;
  position: absolute;
  margin-top: -2px;
  right: 26%;
}
.privacy h4{
  padding-top: 20px;
  font-size: 24px;
  font-family: 'Poppins';
  font-weight: 600;
}
.privacy p{
  font-size: 18px;
  font-family: "Poppins";
  font-weight: 400;
}
.img-blog img{
  border-radius: 0px 60px 0px 0px;
}
.divider{
  width: 98%;
}
.ctabtn {
  float: left;
}
.jobbox h3 {
  text-align: left;
  padding: 0px 0px 30px 0px;
}
.aplynow{
  float: left;
}
.jobbox{
  padding:20px 20px;
}

  .red-circle-car{
    width: 22px;
    position: relative;
    top: 25px;
    /* z-index: -1; */
    right: -35px;
    height: 22px;
    background-color: red;
    border-radius: 50%;
    animation: zoomInOut 2s infinite; /* Adjust the animation duration as needed */
    color: white;
  }
  

}



@media only screen and (min-width: 1440px){
  .redcta {
    width: 24px;
      position: relative;
      top: 45px;
      left: -18px;
      height: 24px;
    background-color: red;
    border-radius: 50%;
    animation: zoomInOut 2s infinite; /* Adjust the animation duration as needed */
    color: white;
  
  }
  .red-circle {
    top: 32px;
    /* z-index: -1; */
    /* right: -13px; */
    height: 26px;
    background-color: red;
    border-radius: 50%;
    animation: zoomInOut 4s infinite; /* Adjust the animation duration as needed */
    color: white;
    transform-origin: top left;
  }
  #cta {
    padding: 0px 40px 20px 60px!important;
    
    background: #ECECEC;
  }
  .about-play-button{
    position: absolute;
    top: 100%;
    right: 21%;
    color: white;
    width: 40px;
    height: 10vh;
  }
  .card-details-about{
    padding: 20px 20px;
    border-radius: 0px 0px 20px 20px;
    background: rgba(0, 0, 0, 0.12);
    backdrop-filter: blur(30px);
    color: white;
    position: absolute;
    top: 0%;
    right: 13%;
    width: 18.3%;
    height: 13%;
    transition: transform 0.3s ease;
  }
  .strip{
    right: 0px;
  }
  .nasmate{
    padding-left: 15px;
  }
  .btnnew button{
    margin-right: 15px;

  }
  .divider{
    width: 96.5%;
  }
  .bannercaption h2{
    margin-top: 70px;
    font-size: 72px;
  }
  .bannercaption h3{
    font-size: 50px;
  }
  .bannercaption h6{
    font-size: 26px;
  }
  .bannerul li {
font-size: 20px;  
}
.cardwiththree img{
  max-height: 320px;
}
  section .headtitlecta{
    margin: 25px auto!important;
    line-height: 32px;
    font-size: 27px;
  }
  .section .headtitle{
    font-size: 40px;
  }
  footer{
    margin-top: -258px!important;
  }
  .footertopcircle{
    position: absolute;
    bottom: 0px;
  }
  .footercircle{
    
    margin-top: 30px;
    width: 86px;
    height: 86px;
    border-radius: 50px;
    background-color: #ca2529;
    float: right;
    right: 20px;
    animation: moveUpDown 1s infinite alternate; /* 3s duration, infinite loop */  
  }
  .footerright ul li{
    font-size: 16px;
  }
  .aboutdivcontent{
    padding: 50px 0px 50px 70px;
  }
  
  @keyframes moveUpDown {
    0% {
      transform: translateY(0); /* Start at the bottom */
     
    }
    100% {
      transform: translateY(-30%); /* Move to the top */
    }
  }
  .mega-div p{
    font-size: 16px;
    
  }

  
}
@media screen and (max-width: 2200px) {
  
}





@media only screen and (max-width: 1024px) {
  /* Your CSS rules for devices with a width of 1024px */
  section .headtitle{
    font-size: 25px;
  }
  .contact-right{
    margin: 20px;
  }
  .bannercaption h6{
    font-size: 18px;
  }
  .bannerul li{
    font-size: 16px;
  }
  .bannercaption h2{
    font-size: 45px;
    margin-top: 0px;
  }.bannerul{
    margin-top: 10px;
  }
  .bannercaption h3{
    font-size: 30px;
  }
  .bgmate h6{
    font-size: 28px;
  }
  #cta{
    padding: 0px 0px 35px 30px!important;
  }
  
  .navbar-brand{
    width: 40%;
  }
  ul li a, .navbar-nav .nav-link{
    padding: 15px 24px 15px 22px;
    font-size: 17px;
    /* white-space: nowrap; */
  }
  .Km-des-about h5{
    font-size: 32px;
  }
  .ctabtn{
    font-size: 12px;
    padding: 10px 13px;
  }
  .footerright ul li{
    white-space: nowrap;
  }
  #cta{
    /* height: 400px; */
  }
  .item__overlay1 h4{
    font-size: 20px;
  }
  .Km-des-about h5{
font-size: 28px;
  }
  .Km-des-about p{
    font-size: 18px;
      }
      .lg{
        display: none;
      }
      .contacttitle h4{
        font-size: 26px;
      }
      .arrowfoter{
        
        margin-top: px;
      }
      .card-details {
        
        height: 20%;
        
      }
}

